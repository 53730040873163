<template>
  <slide-over-right
    :id="`${sorId}-${id}`"
    editType="vehicle-inspection"
    v-on:showModal="showModal"
  >
    <loading :active.sync="isLoading"></loading>

    <div class="flex items-center justify-start mt-5 gap-2">
      <div
        class="flex items-center justify-center cursor-pointer back-button"
        @click="goBack"
      >
        <i class="fas fa-arrow-right"></i>
      </div>
      <div class="header-title">{{ titleText }}</div>
    </div>

    <div class="flex  w-full p-3 mt-5  bg-gray-50 rounded-md ">
      <div class="w-1/3 ">{{ vehicleProfile.name }}</div>
      <div class="w-1/3 flex justify-center">
        <BatteryStatus
          :level="getBatteryLevel"
          :height="`18px`"
          :width="`35px`"
          :text-class="`text-xs text-gray-600 ml-2`"
        />
      </div>
      <div class="w-1/3 flex justify-end ">
        {{ vehicleProfile.qr_code }}
      </div>
    </div>
    <div
      class="mt-5"
      :key="`vi-${itemIndex}`"
      v-for="(item, itemIndex) in inspectionData"
    >
      <InspectionItemCard
        :iData="item"
        @pass-fail-click-status="(e) => changePassFailClickStatus(e, itemIndex)"
      />
    </div>

    <div class=" mt-6">
      <div class="flex w-full  gap-1">
        <AppButton variant="gray-green" width="100%" @click="handlePassAll()">
          Pass All
        </AppButton>
        <AppButton variant="primary" width="100%" @click="handleSubmit()">
          Submit
        </AppButton>
      </div>
    </div>
  </slide-over-right>
</template>

<script>
import { VehicleInspectionConfig } from '@/config/VehicleInspectionConfig'
import { EventBus } from '@/utils/EventBus'
import SlideOverRight from '@/components/modals/SlideOverRight'
import BatteryStatus from '@/components/badge/BatteryStatus.vue'
import InspectionItemCard from '@/composites/vehicle/shared/Inspection/InspectItemCard.vue'
import { fakeDataForInspection } from './helper'

export default {
  name: 'VehicleInspection',
  components: {
    SlideOverRight,
    BatteryStatus,
    InspectionItemCard,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    vehicleProfile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sorId: VehicleInspectionConfig.events.sorId,
      isLoading: false,
      inspectionData: fakeDataForInspection,
    }
  },
  async created() {},
  mounted() {
    EventBus.$on(VehicleInspectionConfig.events.editingData, () => {})
  },
  computed: {
    titleText() {
      return 'Inspection'
    },
    isEditing: function() {
      return !!this.primaryKey
    },
    actionButtonText: function() {
      return 'Submit'
    },
    getBatteryLevel() {
      return this?.vehicleProfile?.lock?.power_level || 0
    },
  },
  watch: {},
  methods: {
    goBack() {
      this.showModal()
    },
    handlePassAll() {
      this.showModal()
    },
    handleSubmit() {
      this.showModal()
    },
    changePassFailClickStatus(event, index) {
      if (event === 'pass') {
        this.inspectionData[index].isPassClicked = true
        this.inspectionData[index].isFailedClicked = false
      }
      if (event === 'fail') {
        this.inspectionData[index].isPassClicked = false
        this.inspectionData[index].isFailedClicked = true
      }
    },
    showModal() {
      dispatchEvent(
        new Event(VehicleInspectionConfig.events.sorToggle(this.id))
      )
    },

    async confirmSubmit() {},
  },
}
</script>

<style lang="scss" scoped>
.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.header-status {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
  margin-right: 10px;
}
.toggle-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.promo-type-card {
  width: 100%;
  height: 200px;
  background: white;
  border-radius: 22px;
  border: 3px dashed #ee9200;
  margin-top: 20px;
  position: relative;
  .promo-right-icon {
    width: 200px;
    height: 55px;
    background: #ee9200;
    position: absolute;
    right: -3px;
    top: -3px;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 60px;
    display: flex;
    span {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      font-family: Roboto;
    }
  }
  .promo-card-body {
    h3 {
      font-family: Roboto;
      font-size: 37px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #0d0d0d;
    }
    span {
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ee9200;
      margin-top: 5px;
    }
  }
}
.multi-selecter {
  span {
    width: 100%;
  }
}
.multiselect__tags {
  border-color: #cbd5e0 !important;
}
</style>

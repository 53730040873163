export const fakeDataForInspection = [
  // {
  //   id: 'i-1',
  //   partTitle: 'Connectivity',
  //   iconSrc: 'accelerator.png',
  //   isPassClicked: false,
  //   isFailedClicked: false,
  //   failReasons: [
  //     { id: 'i-1-fr-1', title: 'Battery Connection' },
  //     { id: 'i-1-fr-2', title: 'GPS Accuracy' },
  //     { id: 'i-1-fr-3', title: 'Data Transmission Consistency' },
  //     { id: 'i-1-fr-4', title: 'Cellular Network Strength' },
  //   ],
  // },
  // {
  //   id: 'i-2',
  //   partTitle: 'Hardware',
  //   iconSrc: 'accelerator.png',
  //   isPassClicked: false,
  //   isFailedClicked: false,
  //   failReasons: [
  //     { id: 'i-2-fr-1', title: 'Frame Material Integrity' },
  //     { id: 'i-2-fr-2', title: 'Handlebar Stability' },
  //     { id: 'i-2-fr-3', title: 'Deck Durability' },
  //     { id: 'i-2-fr-4', title: 'Fender Condition' },
  //   ],
  // },
  {
    id: 'i-3',
    partTitle: 'Battery-Brain-Display',
    iconSrc: 'battery.png',
    isPassClicked: false,
    isFailedClicked: false,
    failReasons: [
      { id: 'i-3-fr-1', title: 'Battery Charging Port' },
      { id: 'i-3-fr-2', title: 'Brain (Control Unit) Wiring' },
      { id: 'i-3-fr-3', title: 'Display Screen Clarity' },
      { id: 'i-3-fr-4', title: 'Control Buttons Functionality' },
    ],
  },
  {
    id: 'i-4',
    partTitle: 'Motor',
    iconSrc: 'sc_motor.jpg',
    isPassClicked: false,
    isFailedClicked: false,
    failReasons: [
      { id: 'i-4-fr-1', title: 'Motor Wiring' },
      { id: 'i-4-fr-2', title: 'Motor Mounting' },
      { id: 'i-4-fr-3', title: 'Motor Heat Generation' },
    ],
  },
  {
    id: 'i-5',
    partTitle: 'Brake',
    iconSrc: 'hand_brake.png',
    isPassClicked: false,
    isFailedClicked: false,
    failReasons: [
      { id: 'i-5-fr-1', title: 'Brake Pad Wear' },
      { id: 'i-5-fr-2', title: 'Brake Cable Tension' },
      { id: 'i-5-fr-3', title: 'Brake Lever Response' },
    ],
  },
  {
    id: 'i-6',
    partTitle: 'Lights',
    iconSrc: 'font_light.png',
    isPassClicked: false,
    isFailedClicked: false,
    failReasons: [
      { id: 'i-6-fr-1', title: 'Headlight Brightness' },
      { id: 'i-6-fr-2', title: 'Taillight Functionality' },
      { id: 'i-6-fr-3', title: 'Turn Signal Visibility' },
    ],
  },
  {
    id: 'i-7',
    partTitle: 'Handlebar',
    iconSrc: 'hand_brake.png',
    isPassClicked: false,
    isFailedClicked: false,
    failReasons: [
      { id: 'i-7-fr-1', title: 'Handlebar Grips Tightness' },
      { id: 'i-7-fr-2', title: 'Handlebar Height Adjustment' },
      { id: 'i-7-fr-3', title: 'Handlebar Folding Mechanism' },
    ],
  },
  {
    id: 'i-8',
    partTitle: 'Grips',
    iconSrc: 'accelerator.png',
    isPassClicked: false,
    isFailedClicked: false,
    failReasons: [
      { id: 'i-8-fr-1', title: 'Grip Material Quality' },
      { id: 'i-8-fr-2', title: 'Grip Attachment Strength' },
    ],
  },
  {
    id: 'i-9',
    partTitle: 'Bell',
    iconSrc: 'Bell.png',
    isPassClicked: false,
    isFailedClicked: false,
    failReasons: [
      { id: 'i-9-fr-1', title: 'Bell Audibility' },
      { id: 'i-9-fr-2', title: 'Bell Mounting Stability' },
    ],
  },
  {
    id: 'i-10',
    partTitle: 'QR Code',
    iconSrc: 'accelerator.png',
    isPassClicked: false,
    isFailedClicked: false,
    failReasons: [
      { id: 'i-10-fr-1', title: 'QR Code Legibility' },
      { id: 'i-10-fr-2', title: 'QR Code Scanning Reliability' },
    ],
  },
  // {
  //   id: 'i-11',
  //   partTitle: 'Head Casting',
  //   iconSrc: 'accelerator.png',
  //   isPassClicked: false,
  //   isFailedClicked: false,
  //   failReasons: [
  //     { id: 'i-11-fr-1', title: 'Head Casting Alignment' },
  //     { id: 'i-11-fr-2', title: 'Headset Bearing Smoothness' },
  //   ],
  // },
  {
    id: 'i-12',
    partTitle: 'Handlebar Tube',
    iconSrc: 'hand_brake.png',
    isPassClicked: false,
    isFailedClicked: false,
    failReasons: [
      { id: 'i-12-fr-1', title: 'Handlebar Tube Diameter' },
      { id: 'i-12-fr-2', title: 'Handlebar Tube Surface Finish' },
    ],
  },
  // {
  //   id: 'i-13',
  //   partTitle: 'Cycl Turn Signals',
  //   iconSrc: 'accelerator.png',
  //   isPassClicked: false,
  //   isFailedClicked: false,
  //   failReasons: [
  //     { id: 'i-13-fr-1', title: 'Turn Signal Activation' },
  //     { id: 'i-13-fr-2', title: 'Turn Signal Visibility in Day/Night' },
  //   ],
  // },
  {
    id: 'i-14',
    partTitle: 'Wheels',
    iconSrc: 'front_tire.png',
    isPassClicked: false,
    isFailedClicked: false,
    failReasons: [
      { id: 'i-14-fr-1', title: 'Wheel Bearings Smoothness' },
      { id: 'i-14-fr-2', title: 'Wheel Alignment' },
    ],
  },
  {
    id: 'i-15',
    partTitle: 'Front Tire',
    iconSrc: 'front_tire.png',
    isPassClicked: false,
    isFailedClicked: false,
    failReasons: [
      { id: 'i-15-fr-1', title: 'Tire Tread Depth' },
      { id: 'i-15-fr-2', title: 'Tire Inflation Pressure' },
    ],
  },
  {
    id: 'i-16',
    partTitle: 'Rear Tire',
    iconSrc: 'front_tire.png',
    isPassClicked: false,
    isFailedClicked: false,
    failReasons: [
      { id: 'i-16-fr-1', title: 'Tire Wear Patterns' },
      { id: 'i-16-fr-2', title: 'Tire Condition (Cuts, Tears)' },
    ],
  },
  // {
  //   id: 'i-17',
  //   partTitle: 'Brand-Stickers',
  //   iconSrc: 'accelerator.png',
  //   isPassClicked: false,
  //   isFailedClicked: false,
  //   failReasons: [
  //     { id: 'i-17-fr-1', title: 'Brand Sticker Adhesion' },
  //     { id: 'i-17-fr-2', title: 'Brand Logo Clarity' },
  //   ],
  // },
]

export const VehicleInspectionConfig = {
  api: {},
  events: {
    name: 'vehicle-inspection',
    // refresh-index-data
    refresh: `rid-vehicle-inspection`,
    // slideover-right
    sorId: 'vehicle-inspection',
    sorOpen: 'sor-open-vehicle-inspection',
    sorClose: 'sor-close-vehicle-inspection',
    sorToggle: (id) => `sor-toggle-vehicle-inspection-${id}`,
    // editing-data
    editingData: 'edit-vehicle-inspection-data',
    // viewing-data
    viewingData: 'view-vehicle-inspection-data',
  },
}

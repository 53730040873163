<template>
  <div class="card bg-gray-100 p-3">
    <div class=" grid grid-cols-12 ">
      <div class="col-span-2">
        <img
          :src="`${getImage(iData.iconSrc)}`"
          alt="image"
          class=" w-16 h-16 rounded-md"
        />
      </div>
      <div class="col-span-9">
        <p class="font-bold pl-2 text-lg">{{ iData.partTitle }}</p>
      </div>
      <div class="col-span-1 cursor-pointer" @click="toggleFailReason">
        <i v-if="showFailReason" class=" fas fa-chevron-up"></i>
        <i v-else class=" fas fa-chevron-down"></i>
      </div>
    </div>
    <div class="mt-5 grid grid-cols-12 gap-2">
      <div class="col-span-6">
        <AppButton
          :variant="iData.isPassClicked ? 'green' : 'gray-green'"
          width="100%"
          @click="handlePassFailClickStatus('pass')"
        >
          Pass
        </AppButton>
      </div>
      <div class="col-span-6">
        <AppButton
          :variant="iData.isFailedClicked ? 'red' : 'gray-red'"
          width="100%"
          @click="handlePassFailClickStatus('fail')"
        >
          Fail
        </AppButton>
      </div>
    </div>
    <accordion-transition :expanded="showFailReason">
      <div
        class="mt-5"
        :key="`vi-${itemIndex}`"
        v-for="(reason, itemIndex) in iData.failReasons"
      >
        <t-checkbox wrapped :label="reason.title" :name="reason.title" />
      </div>
    </accordion-transition>
  </div>
</template>
<script>
import AccordionTransition from '@/components/accordion/AccordionTransition.vue'
export default {
  components: { AccordionTransition },
  name: 'InspectionItemCard',
  props: {
    iData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showFailReason: false,
    }
  },
  computed: {
    // getImage() {
    //   return require('@/assets/img/vehicle-inspection/accelerator.png')
    // },
  },
  methods: {
    getImage(src) {
      return require(`@/assets/img/vehicle-inspection/${src}`)
    },
    toggleFailReason() {
      this.showFailReason = !this.showFailReason
      console.log('showFailReason', this.showFailReason)
    },
    handlePassFailClickStatus(type) {
      if (type === 'pass') {
        this.$emit('pass-fail-click-status', type)
      }
      if (type === 'fail') {
        this.$emit('pass-fail-click-status', type)
        this.showFailReason = true
      }
    },
  },
}
</script>
<style lang="scss">
.card {
  min-width: 200px;
  height: auto;
  box-shadow: 0 4px 11px 0 #3134692c;
  @apply rounded-lg;
}
</style>

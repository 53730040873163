<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
      <section>
        <!-- row-1 -->
        <div class="grid grid-cols-2 gap-4">
          <AppInput
            v-model="form.name"
            type="text"
            :name="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.vehicleName'
              )
            "
            :label="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.vehicleName'
              )
            "
            rules="required"
            placeholder="e.g. Otoride 101"
          />

          <AppInput
            v-model="form.qr_code"
            type="text"
            :name="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.vehicleQRCode'
              )
            "
            :label="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.vehicleQRCode'
              )
            "
            rules="required"
            placeholder="e.g. 1000100001"
          />
        </div>
        <!-- /row-1 -->

        <!-- row-2 -->
        <div class="grid grid-cols-2 gap-4">
          <AppInput
            v-model="form.manufacturer"
            type="richselect"
            :name="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.vehicleManufacturer'
              )
            "
            :label="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.vehicleManufacturer'
              )
            "
            :placeholder="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.placeHolder.vehicleManufacturer'
              )
            "
            rules="required"
            text-attribute="name"
            value-attribute="id"
            :options="vehicleManufacturers"
            hide-search-box
          />

          <AppInput
            v-model="form.bike_category"
            type="richselect"
            :name="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.vehicleType'
              )
            "
            :label="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.vehicleType'
              )
            "
            :placeholder="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.placeHolder.vehicleType'
              )
            "
            rules="required"
            text-attribute="text"
            value-attribute="value"
            :options="vehicleTypes"
            hide-search-box
          />
        </div>
        <!-- /row-2 -->

        <!-- row-3 -->
        <div class="grid grid-cols-2 gap-4">
          <AppInput
            v-model="form.fleet"
            type="richselect"
            :name="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.assignedFleet'
              )
            "
            :label="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.assignedFleet'
              )
            "
            :placeholder="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.placeHolder.assignedFleet'
              )
            "
            text-attribute="name"
            value-attribute="id"
            :options="fleets"
            hide-search-box
          />

          <AppInput
            :value="getSelectedFleetLocation"
            type="text"
            :name="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.fleetLocation'
              )
            "
            :label="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.fleetLocation'
              )
            "
            placeholder="--"
            disabled
          />
        </div>
        <!-- /row-3 -->
        <!-- row-4 -->
        <div class="grid grid-cols-2 gap-4" v-if="isEditing">
          <AppInput
            v-model="form.rent_area.id"
            type="richselect"
            :name="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.assignedRentStation'
              )
            "
            :label="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.assignedRentStation'
              )
            "
            :placeholder="getRentStationPlaceholder"
            :disabled="getRentStationDisabledState"
            text-attribute="text"
            value-attribute="value"
            :options="getRentStationList"
          />

          <AppInput
            v-model="form.manual_lock_code"
            type="text"
            :name="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.manualLockCode'
              )
            "
            :label="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.manualLockCode'
              )
            "
            placeholder="e.g. 1234"
          />
        </div>
        <div>
          <AppInput
            type="richselect"
            :name="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.vehicleModel'
              )
            "
            :label="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.vehicleModel'
              )
            "
            :placeholder="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.placeHolder.vehicleModel'
              )
            "
            rules=""
            :options.sync="vehicleModels"
            valueAttribute="id"
            textAttribute="title"
            :hide-search-box="true"
            v-model="form.organization_vehicle_model"
          />
        </div>
        <!-- /row-4 -->

        <div>
          <TCheckbox
            v-model="form.is_under_simulation"
            :label="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.enableLocationSimulationMode'
              )
            "
            wrapped
            @change="onUseLatLongField"
          />

          <transition name="fade">
            <div
              class="grid grid-cols-2 gap-4"
              ref="latLongFieldRef"
              v-if="form.is_under_simulation"
            >
              <AppInput
                v-model="latitude"
                type="text"
                :name="
                  $t(
                    'components.vehicleManagement.addEdit.steps.vehicle.title.latitude'
                  )
                "
                :label="
                  $t(
                    'components.vehicleManagement.addEdit.steps.vehicle.title.latitude'
                  )
                "
                rules="required"
                placeholder="e.g. 24.4408"
              />

              <AppInput
                v-model="longitude"
                type="text"
                :name="
                  $t(
                    'components.vehicleManagement.addEdit.steps.vehicle.title.longitude'
                  )
                "
                :label="
                  $t(
                    'components.vehicleManagement.addEdit.steps.vehicle.title.longitude'
                  )
                "
                rules="required"
                placeholder="e.g. 90.1204"
              />
            </div>
          </transition>
        </div>

        <div>
          <TCheckbox
            v-model="useDescriptionField"
            :label="
              $t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.addDescriptionOptional'
              )
            "
            wrapped
            @change="onUseDescriptionField"
          />

          <transition name="fade">
            <AppInput
              ref="descriptionFieldRef"
              v-if="useDescriptionField"
              v-model="form.description"
              type="textarea"
              :name="
                $t(
                  'components.vehicleManagement.addEdit.steps.vehicle.title.description'
                )
              "
              :label="
                $t(
                  'components.vehicleManagement.addEdit.steps.vehicle.title.description'
                )
              "
            />
          </transition>
        </div>
      </section>

      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { useEndpoints } from '@/composables'
import { deepCompareObjects, xMan } from '@/utils'
// import { getFormModel } from '.'

export default {
  name: 'VehicleAddEditStep2',

  components: {},

  props: {
    // vehicle.lock.id (created or selectedLockId)
    primaryKeyLock: {
      required: false,
    },
    fleets: {
      type: Array,
      required: true,
    },
    vehicleTypes: {
      type: Array,
      required: true,
    },
    // todo: remove ths
    selectedLockId: {
      type: String,
      required: true,
    },
    vehicleManufacturers: {
      type: Array,
      required: true,
    },
    vehicleModels: {
      type: Array,
      required: true,
    },

    primaryKey: {
      required: true,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    // const { payment, link, domain } = getFormModel({ step: 3 })
    // console.log(payment, link, domain, 'form3')

    return {
      isLoading: false,
      isRentStationListLoading: false,
      isVehicleModelLoading: false,
      useDescriptionField: false,
      rentStationList: [],
      form: {
        full_name: '',
        username: '',
        email: '',
        phone_number: '',
      },
      latitude: '',
      longitude: '',
      password: undefined,
      passwordConfirmation: undefined,
      // isOrgUserCreated: false,
    }
  },

  computed: {
    isOrgUserCreated() {
      return !!this.orgUserId
    },

    getPasswordValidationRules() {
      if (!this.isOrgUserCreated) {
        return 'required|min:8'
      }

      return this.isEditing ? 'min:8' : 'required|min:8'
    },

    getSelectedFleet() {
      return this.fleets.find((item) => item.id === this.form.fleet)
    },
    getSelectedFleetLocation() {
      return this.getSelectedFleet?.geofence?.name || '--'
    },
    getSelectedFleetLocationId() {
      return this.getSelectedFleet?.geofence?.id || ''
    },
    getRentStationList() {
      return this.rentStationList
    },
    getRentStationPlaceholder() {
      if (this.rentStationList.length > 0)
        return this.$t(
          'components.vehicleManagement.addEdit.steps.vehicle.placeHolder.assignedRentStation.selectRentStation'
        )
      if (this.isRentStationListLoading)
        return this.$t(
          'components.vehicleManagement.addEdit.steps.vehicle.placeHolder.assignedRentStation.loading'
        )
      return this.$t(
        'components.vehicleManagement.addEdit.steps.vehicle.placeHolder.assignedRentStation.noRentStationFound'
      )
    },

    getRentStationDisabledState() {
      if (!this.rentStationList) return true
      if (this.isRentStationListLoading) return true
      return false
    },
  },

  watch: {
    // sync props.formData with $data.from
    formData: {
      immediate: true,
      deep: false,
      handler(data) {
        if (data) {
          this.form = {
            ...data,
            rent_area: data.rent_area ?? { id: '' },
            organization_vehicle_model: data.organization_vehicle_model
              ? data.organization_vehicle_model.id
              : '',
          }
          const [lat, long] = this.form.simulated_new_loc_coord
            ? this.form.simulated_new_loc_coord?.split(',')
            : [null, null]
          this.latitude = lat
          this.longitude = long
          // this.isOrgUserCreated = !!this.form.username
          console.log(this.form, this.isOrgUserCreated, 'ff4')
          if (data.geofence) {
            this.rentStationList = []
            this.isRentStationListLoading = true
            this.$http
              .get(
                `${useEndpoints.geoFence.rentStationArea.index()}?geofence=${
                  data.geofence.id
                }`
              )
              .then((res) => {
                this.rentStationList = res.data.data.map((area) => {
                  return {
                    text: area.name,
                    value: area.id,
                  }
                })
                this.rentStationList = [
                  {
                    text: 'None',
                    value: null,
                  },
                  ...this.rentStationList,
                ]
              })
              .catch((err) => console.log(err))
              .finally(() => (this.isRentStationListLoading = false))
          } else {
            this.rentStationList = [
              {
                text: 'None',
                value: null,
              },
            ]
          }
        }
      },
    },

    // notify form is dirty & user should confirm before exiting
    form: {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        // don't notify if editing data & user input data are same
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        // otherwise notify whenever there's new input / change
        this.$emit('dirty', { step: 4, data: this.form })
      },
    },
    getSelectedFleetLocationId: {
      immediate: false,
      deep: true,
      handler(geofenceId) {
        console.log('fleet-location-data', geofenceId)
        if (geofenceId) {
          this.rentStationList = []
          this.isRentStationListLoading = true
          this.$http
            .get(
              `${useEndpoints.geoFence.rentStationArea.index()}?geofence=${geofenceId}`
            )
            .then((res) => {
              this.rentStationList = res.data.data.map((area) => {
                return {
                  text: area.name,
                  value: area.id,
                }
              })
              this.rentStationList = [
                {
                  text: 'None',
                  value: null,
                },
                ...this.rentStationList,
              ]
            })
            .catch((err) => console.log(err))
            .finally(() => (this.isRentStationListLoading = false))
        } else {
          this.rentStationList = [
            {
              text: 'None',
              value: null,
            },
          ]
        }
      },
    },
  },

  methods: {
    async submit() {
      await this.$refs.submitButton.click()
    },

    async submitStep() {
      const url =
        this.isEditing || this.primaryKey
          ? useEndpoints.vehicle.update(this.primaryKey)
          : useEndpoints.vehicle.create()

      const method = this.isEditing || this.primaryKey ? 'PATCH' : 'POST'
      if (this.latitude && this.longitude) {
        this.form.simulated_new_loc_coord = this.latitude.concat(
          ',',
          this.longitude
        )
      }

      if (!this.form.is_under_simulation) {
        // delete this.form.is_under_simulation
        delete this.form.simulated_new_loc_coord
      }
      if (!this.form.organization_vehicle_model) {
        delete this.form.organization_vehicle_model
      }
      delete this.form.geofence

      let formDataProxy
      if (!this.isEditing) {
        delete this.form.rent_area
        formDataProxy = { ...this.form }
      } else {
        formDataProxy = {
          ...this.form,
          rent_area: this.form.rent_area.id ? this.form.rent_area.id : '',
        }
      }

      // add additional required data
      formDataProxy.lock = this.primaryKeyLock

      const data = new xMan(formDataProxy).toFormData()
      console.log('v-step2', formDataProxy, data)
      this.isLoading = true
      await this.$http({ method, url, data })
        .then((res) => {
          // todo: push to unassignedLocks
          console.log({ save: res.data })
          this.$emit('save', { step: 2, data: res.data })

          const message = `Vehicle ${
            this.isEditing || this.primaryKey ? 'updated' : 'added '
          } successfully`

          this.$notify({
            group: 'bottomLeft',
            type: 'success',
            title: 'Success',
            text: message,
          })
        })
        .catch((err) => {
          // todo: emit fail?
          console.error('Error occured!', err.response)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occured!',
            text: err.response,
          })
        })
        .finally(() => (this.isLoading = false))
    },
    onUseLatLongField(e) {
      if (e) {
        const i = setTimeout(() => {
          this.$refs.latLongFieldRef.focus()
          clearTimeout(i)
        }, 150)
      }
    },

    onUseDescriptionField(e) {
      if (e) {
        const i = setTimeout(() => {
          this.$refs.descriptionFieldRef.focus()
          clearTimeout(i)
        }, 150)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.section-name {
  @apply text-sm font-bold text-gray-500;
}
</style>

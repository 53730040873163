export const fakeDataForRepair = [
  {
    id: 'r-1',
    partTitle: 'Motor',
    repairs: [
      {
        id: 'r-1-rs-1',
        title: 'Motor Wiring',
        isShowOption: false,
        options: [
          { id: 'r-1-rs-1-o-1', name: 'Front Wheel' },
          { id: 'r-1-rs-1-o-1', name: 'Rear Wheel' },
        ],
      },
      {
        id: 'r-1-rs-1',
        title: 'Motor Mounting',
        isShowOption: false,
        options: [
          { id: 'r-1-rs-1-o-1', name: 'Front Wheel' },
          { id: 'r-1-rs-1-o-1', name: 'Rear Wheel' },
        ],
      },
    ],
  },
]
